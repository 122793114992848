import { isDebug, isDevEnv } from './env';

export const clog = (...args: any[]) => {
  const enabled = isDevEnv() || isDebug();
  if (!enabled) {
    return;
  }

  console.log(...args);
};

export const cerr = (...args: any[]) => {
  clog('ERROR:', ...args);
};
