export const isDevEnv = () => {
  try {
    return process.env.NODE_ENV === 'development';
  } catch (e) {
    return false;
  }
};

export const isDebug = () => {
  try {
    return (
      process.env.DEBUG === 'debug' || process.env.NEXT_PUBLIC_DEBUG === 'debug'
    );
  } catch (e) {
    return false;
  }
};
